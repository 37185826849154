<template>
  <div>
    <div class="comment-box">
            <textarea @input="handleInput" v-model="inputText" class="comment-box__input"
                      maxlength="500"
                      placeholder="欢迎提出更多的建议"></textarea>
      <div class="comment-box__counter" v-if=" count >= 0 ">剩余 {{count}}</div>
      <div class="comment-box__counter warning" v-else>已超出 {{-count}}</div>
    </div>
    <div class="submit-btn" @click="onSubmitTap">提交</div>
  </div>
</template>

<script>
import {Field} from 'vant'
import to from '@/utils/to'
import { inserFeedback } from '@/api/mine'

export default {
  components: {
    [Field.name]: Field
  },

  data() {
    return {
      id: '',
      count: 500,
      inputText: ''
    }
  },

  mounted() {
    this.id = this.$route.query.id
  },

  methods: {
    handleInput() {
      this.count = 500 - this.inputText.length
    },
    async onSubmitTap() {
      if (this.inputText.trim().length <= 0) {
        this.$showFail("请填写评论后提交");
        return;
      }

      this.$showLoading('提交中...')
      /* eslint-disable */
      let [err, res] = await to(inserFeedback(this.inputText))
      /* eslint-enable*/
      this.$hideLoading()

      if (err) {
        this.$showFail("提交失败");
        return
      }

      this.$showSuccess("提交成功", () => {
        this.$router.go(-1);
      });

      return
    }
  }
}
</script>

<style>
  .comment-box {
    margin: 16px 16px 0 16px;
    padding: 14px 12px;
    background: #f2f4f8;

  }

  .comment-box__input {
    width: 100%;
    height: 150px;
    border: none;
    background: #f2f4f8;
    font-size: 14px;
    font-weight: 500;
  }

  .comment-box__counter {
    font-size: 12px;
    text-align: right;
    color: #888;
  }

  .submit-btn {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    height: 36px;
    line-height: 36px;
    text-align: center;
    width: 230px;
    margin: 0 auto;
    margin-top: 38px;
    background: #f84c1e;
    border-radius: 6px;
    border: 1px solid #f84c1e;
  }
</style>